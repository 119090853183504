import { Input, Button, Divider, Box, Text, IconButton } from "@chakra-ui/react";
import { FiUploadCloud } from 'react-icons/fi';
import { useState } from "react";
import styled from "styled-components";

const ModiContainer = styled.div`
    background-color:#101828;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    padding: 24px;
    align-self: stretch;
    border-radius: 20px;
    width:100%;
`
const InputwithLabel = ({ label, placeholder, type }) => {
    return (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '6px', width: '50%' }}>
            <label style={{ fontSize: '14px', color: 'rgba(255, 255, 255, 0.50)' }}>{label}</label>
            <Input style={{ color: 'white', backgroundColor: 'rgba(255, 255, 255, 0.10)' }} placeholder={placeholder} />
        </div>
    )
}


function Profile() {
    const [files, setFiles] = useState([]);
    const handleFileChange = (event) => {
        setFiles(Array.from(event.target.files));
        console.log(event.target.files);
    };
    const handleUpload = async () => {
        const formData = new FormData();

        for (let i = 0; i < files.length; i++) {
            formData.append("files", files[i]);
        }
        // const userid = JSON.parse(localStorage.getItem("user"))._id;
        // try {
        //     await axios
        //         .post(`${BASE_URL}/api/upload`, formData, {
        //             headers: {
        //                 userID: userid,
        //                 watermarktext: watermark,
        //             },
        //         })
        //         .then((res) => {
        //             console.log(res.data);
        //         })
        //         .catch((err) => {
        //             console.log(err);
        //         });
        // } catch (err) {
        //     console.log(err);
        // }
    };
    return (
        <div style={{ display: 'flex', padding: '44px 16px 16px 18px', flexDirection: 'column', gap: '32px', alignSelf: 'stretch', alignItems: 'flex-start' }}>
            <p style={{ color: 'white', fontSize: '24px' }}>Personal Info</p>
            <ModiContainer>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', alignSelf: 'stretch', gap: '20px' }}>
                    <img src={!files[0] ? './images/Avatar.png' : URL.createObjectURL(files[0])} alt="avatar" width="126px" height="126px" style={{ objectFit: 'cover', justifyContent: 'center' }} />
                    <Box
                        display="flex"
                        alignItems="center"
                        padding={'16px 24px'}
                        justifyContent="center"
                        borderRadius={'12px'}
                        width={'30%'}
                        backgroundColor={'rgba(255, 255, 255, 0.05)'}
                    >
                        <label
                            htmlFor="dropzone-file"
                            className="inline-flex flex-col items-center justify-center w-full h-40 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-100"
                        >
                            <Box
                                display="flex"
                                flexDirection="column"
                                alignItems="center"
                                justifyContent="center"
                                pt="3"
                                pb="4"
                            >
                                <IconButton
                                    py="2"
                                    px="4"
                                    rounded="md"
                                    icon={<FiUploadCloud color="white" />}
                                    bg={"#9E77ED"}
                                    color="white"
                                    onClick={handleUpload} />
                                <Text mb="1" fontSize="sm" color="gray.500" fontWeight="semibold">
                                    <span style={{ color: '#9E77ED' }}>Click to upload</span> or drag and drop
                                </Text>
                                <Text fontSize="xs" color="gray.500">
                                    SVG, PNG, JPG or GIF (MAX. 800x400px)
                                </Text>
                            </Box>
                            <Input
                                id="dropzone-file"
                                type="file"
                                display="none"
                                accept=".jpg, .png, .jpeg, .gif, .svg, .bmp"
                                multiple
                                onChange={handleFileChange}
                            />

                        </label>
                    </Box>
                </div>
                <div style={{display:'flex', flexDirection:'column', gap:'24px', width:'100%'}}>
                    <div style={{ display: 'flex', gap: '24px', justifyContent: 'space-between', width: '100%' }}>
                        <InputwithLabel label={"First Name"} placeholder={"First Name"}></InputwithLabel>
                        <InputwithLabel label={"Last Name"} placeholder={"Last Name"}></InputwithLabel>
                    </div>
                    <div style={{ display: 'flex', gap: '24px', justifyContent: 'space-between', width: '100%' }}>
                        <InputwithLabel label={"Username"} placeholder={"Username"}></InputwithLabel>
                        <InputwithLabel label={"Email address"} placeholder={"example.com"}></InputwithLabel>
                    </div>
                </div>
                <Divider />
                <div style={{ display: 'flex', alignSelf: 'flex-end', gap: '10px' }}>
                    <Button>Cancel</Button>
                    <Button background={'linear-gradient(45deg, #FF6C6C 0%, #DD7BFF 100%)'} _hover={{ background: '#DD7BFF' }} color={'white'} padding={'10px 18px'} borderRadius={'8px'} boxShadow={'0px 1px 2px 0px rgba(16, 24, 40, 0.05)'}>
                        Save Changes
                    </Button>
                </div>
            </ModiContainer>
        </div>
    )
}

export default Profile;