import { styled } from "styled-components";
import { Badge, Button } from "@chakra-ui/react";
import { FiUpload } from 'react-icons/fi';

const ContainerSection = styled.div`
    display: flex;
    padding: 44px 16px 16px 18px;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
    flex: 1 0 0;
    align-self: stretch;
`
const ImageComponent = ({ imgName }) => {
    return (
        <div style={{ width: '150px', height: '210px', overflow: 'hidden' }}>
            <img src={`./images/${imgName}.png`} alt="fifa" style={{ borderRadius: '10px', objectFit: 'cover', objectPosition: 'center', width: '100%', height: '100%' }} />
        </div>
    )
}

function Tournament() {
    return (
        <ContainerSection>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '32px', alignItems: 'flex-start', alignSelf: 'stretch' }}>
                <div style={{ display: 'flex', gap: '10px' }}>
                    <p style={{ fontFamily: 'Monopol', fontSize: '24px', color: 'var(--base-white, #FFF)' }} >UPCOMING TOURNAMENT ALERT!</p>
                    <Badge borderRadius={'16px'} display={'flex'} alignItems={'center'} padding={'4px 12px'} color={'var(--orange-500, #EF6820)'} background={'rgba(255, 98, 0, 0.30)'} fontSize={'14px'}>
                        Hot 🔥
                    </Badge>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '16px', alignItems: 'flex-start', alignSelf: 'stretch' }}>
                    <img src="./images/fifa.png" alt="fifa" style={{ borderRadius: '10px', width: '100%' }} />
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '24px', alignItems: 'flex-start', alignSelf: 'stretch' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', gap: '12px', alignItems: 'flex-start', alignSelf: 'stretch' }}>
                            <p style={{ fontFamily: 'Monopol', fontSize: '24px', color: 'var(--base-white, #FFF)' }}>FIFA 2023</p>
                            <p style={{ fontFamily: 'Monopol', fontSize: '18px', color: 'var(--gray-300, #D0D5DD)' }}>Get ready to unleash your skills in our next grand event. With significant prizes and unlimited excitement, every game is a chance to prove your mettle. Stay tuned for details and be part of the next gaming revolution!"</p>
                        </div>
                        <div style={{ display: 'flex', alignSelf: 'flex-start', gap: '10px' }}>
                            <Button background={'linear-gradient(45deg, #FF6C6C 0%, #DD7BFF 100%)'} _hover={{ background: '#DD7BFF' }} color={'white'} padding={'10px 18px'} borderRadius={'8px'} boxShadow={'0px 1px 2px 0px rgba(16, 24, 40, 0.05)'}>
                                Enlist for Tournament
                            </Button>
                            <Button><p>Share with Friends &nbsp;</p><FiUpload /></Button>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '24px', alignSelf: 'stretch', alignItems: 'flex-start' }}>
                <p style={{ fontFamily: 'Monopol', fontSize: '24px', color: 'var(--base-white, #FFF)' }}>Enlisted Tournaments</p>
                <div style={{ display: 'flex', gap: '12px' }}>
                    <ImageComponent imgName="fifa1" />
                    <ImageComponent imgName="streetfighter" />
                    <ImageComponent imgName="fortnite" />
                    <ImageComponent imgName="warzone" />
                    <ImageComponent imgName="mariokart" />
                </div>
            </div>

        </ContainerSection>
    )
}

export default Tournament;