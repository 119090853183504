import React from 'react';
import { Box, CloseButton, Flex, Icon, useColorModeValue, Text, Drawer, DrawerContent, useDisclosure, Divider } from '@chakra-ui/react';
import { FiCamera, FiClock, FiUser, FiLogOut } from 'react-icons/fi';
import { Gamepad } from '@styled-icons/fa-solid/Gamepad';
import { useNavigate } from 'react-router-dom';
import LogoSection from "../../components/Logo";
import Bet from './bet';
import Tournament from './tournament';
import Profile from './profile';
import LiveBetting from './live';

const LinkItems = [
    { name: 'Live', icon: FiCamera, link: '/live' },
    { name: 'Current bets', icon: FiClock, link: '/bet' },
    { name: 'Tournament', icon: Gamepad, link: '/tournament' },
    { name: 'Profile', icon: FiUser, link: '/profile' },
];

const Dashboard = ({ index }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const navigate = useNavigate()
    const logout=()=>{
        navigate('/')
    }
    let ProComponent
    if (index === 1) ProComponent = <LiveBetting />;
    else if (index === 2) ProComponent = <Profile />;
    else if (index === 3) ProComponent = <Tournament />;
    else if (index === 4) ProComponent = <Bet />;
    return (
        <Box minH="100vh" backgroundColor={'black'}>
            <Box
                color={'white'}
                backgroundColor={'rgba(255, 255, 255, 0.05)'}
                w={{ base: 'full', md: 60 }}
                pos="fixed"
                h="full"
                display={'flex'}
                flexDirection={'column'}
                padding={'32px 0px'}
                justifyContent={'space-between'}
            >
                <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                    <Flex h="20" alignItems="center" mx="8" justifyContent="space-between">
                        <Text fontSize="2xl" fontFamily="monospace" fontWeight="bold">
                            <LogoSection logoheight={60} logowidth={103} />
                        </Text>
                        <CloseButton display={{ base: 'flex', md: 'none' }} onClick={onClose} />
                    </Flex>
                    <div>
                        {LinkItems.map((link) => (
                            <Box
                                style={{ textDecoration: 'none' }}
                                _focus={{ boxShadow: 'none' }}
                                key={link.name}
                            >
                                <Flex
                                    align="center"
                                    p="4"
                                    mx="4"
                                    borderRadius="lg"
                                    role="group"
                                    cursor="pointer"
                                    _hover={{
                                        bg: 'rgba(255, 255, 255, 0.20)',
                                        color: 'black',
                                    }}
                                    onClick={() => navigate(link.link)}
                                >
                                    {link.icon && (
                                        <Icon
                                            mr="4"
                                            fontSize="24"
                                            _groupHover={{
                                                color: 'rgba(205, 187, 255, 0.5)',
                                            }}
                                            color={'rgba(205, 187, 255, 0.5)'}
                                            as={link.icon}
                                        />
                                    )}
                                    {link.name}
                                </Flex>
                            </Box>
                        ))}
                    </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                    <Divider />
                    <Box
                        bottom={0}
                        style={{ textDecoration: 'none' }}
                        _focus={{ boxShadow: 'none' }}
                    >
                        <Flex
                            align="center"
                            p="4"
                            mx="4"
                            borderRadius="lg"
                            role="group"
                            cursor="pointer"
                            _hover={{
                                bg: 'rgba(255, 255, 255, 0.20)',
                                color: 'black',
                            }}
                            onClick={()=>logout()}
                        >
                            <div style={{ display: 'flex', gap: '40px' }}>
                                <div style={{ display: 'flex', gap: '12px' }}>
                                    <img src="./images/Avatar.png" alt="avatar" style={{ objectFit: 'cover', justifyContent: 'center', borderRadius: '50%', width: '40px', height: '40px' }} />
                                    <div>
                                        <p style={{ color: 'white', fontSize: '14px' }}>Mahdiverse</p>
                                        <p style={{ color: 'var(--gray-400, #98A2B3)', fontSize: '14px' }}>0xcd3afb4a35f1</p>
                                    </div>
                                </div>
                                <div style={{padding:'8px'}}>
                                    <FiLogOut size={'20px'} color='red'/>
                                </div>
                            </div>
                        </Flex>
                    </Box>
                </div>
            </Box>
            <Drawer
                isOpen={isOpen}
                placement="left"
                onClose={onClose}
                returnFocusOnClose={false}
                onOverlayClick={onClose}
                size="full"
            >
                <DrawerContent >
                    <Box
                        bg={useColorModeValue('white', 'gray.900')}
                        borderRight="1px"
                        borderRightColor={useColorModeValue('gray.200', 'gray.700')}
                        w={{ base: 'full', md: 60 }}
                        pos="fixed"
                        h="full"
                    >
                        <Flex h="20" alignItems="center" mx="8" justifyContent="space-between">
                            <Text fontSize="2xl" fontFamily="monospace" fontWeight="bold">
                                Logo
                            </Text>
                            <CloseButton display={{ base: 'flex', md: 'none' }} onClick={onClose} />
                        </Flex>
                        {LinkItems.map((link) => (
                            <Box
                                as="a"
                                style={{ textDecoration: 'none' }}
                                _focus={{ boxShadow: 'none' }}
                                key={link.name}
                            >
                                <Flex
                                    align="center"
                                    p="4"
                                    mx="4"
                                    borderRadius="lg"
                                    role="group"
                                    cursor="pointer"
                                    _hover={{
                                        bg: 'cyan.400',
                                        color: 'white',
                                    }}
                                >
                                    {link.icon && (
                                        <Icon
                                            mr="4"
                                            fontSize="16"
                                            _groupHover={{
                                                color: 'white',
                                            }}
                                            as={link.icon}
                                        />
                                    )}
                                    {link.name}
                                </Flex>
                            </Box>
                        ))}
                    </Box>
                </DrawerContent>
            </Drawer>
            <Box ml={{ base: 0, md: 60 }} p="4">
                {ProComponent}
            </Box>
        </Box>
    );
};

export default Dashboard;