
import React, { useState } from "react";
import { Input, IconButton, Badge, Button, Switch, Menu, MenuItem, MenuButton, MenuList } from "@chakra-ui/react";
import { FiSend, FiArrowLeft, FiArrowRight, FiEye, FiArrowDown, FiGlobe, FiClock } from 'react-icons/fi';

const ImageComponent = ({ imgName }) => {
    return (
        <div style={{ width: '350px', height: '248px', overflow: 'hidden' }}>
            <img src={`./images/${imgName}.png`} alt="fifa" style={{ borderRadius: '16px', objectFit: 'cover', objectPosition: 'center', width: '100%', height: '100%' }} />
        </div>
    )
}

const BadgeGroup = () => {
    return (
        <div style={{ display: 'flex', gap: '10px', bottom: '0', alignItems: 'end' }}>
            <Badge backgroundColor={'#004EEB'} borderRadius={'16px'} padding={'4px 8px'} alignItems={'center'} gap={'4px'} height={'25px'} display={'flex'}>
                <div style={{ display: 'flex', color: 'white' }}><FiEye size={'16px'} /> 15K</div>
            </Badge>
            <Badge backgroundColor={'#6927DA'} borderRadius={'16px'} padding={'4px 8px'} alignItems={'center'} gap={'4px'} height={'25px'} display={'flex'}>
                <div style={{ display: 'flex', color: 'white' }}><FiGlobe size={'16px'} /> English</div>
            </Badge>
            <Badge backgroundColor={'#F79009'} borderRadius={'16px'} padding={'4px 8px'} alignItems={'center'} gap={'4px'} height={'25px'} display={'flex'}>
                <div style={{ display: 'flex', color: 'white' }}><FiClock size={'16px'} /> 15min ago</div>
            </Badge>
        </div>
    )
}

const LiveMatchComponent = () => {
    return (
        <div>
            <ImageComponent imgName={'video-fortnite'} />
            <div style={{ display: 'flex', gap: '10px', marginTop: '24px' }}>
                <img src="./images/Avatar.png" alt="avatar" style={{ objectFit: 'cover', justifyContent: 'center', borderRadius: '50%', width: '64px', height: '64px' }} />
                <div>
                    <p style={{ color: 'white', fontSize: '18px' }}>FIFA 2023</p>
                    <p style={{ color: '#D0D5DD', fontSize: '14px' }}>David Beckham</p>
                    <BadgeGroup />
                </div>
            </div>
        </div>
    )
}

function LiveBetting() {
    const [messages, setMessages] = useState([]);
    const [inputValue, setInputValue] = useState("");

    const handleInputChange = (event) => {
        setInputValue(event.target.value);
    };

    const handleSendMessage = () => {
        if (inputValue.trim() !== "") {
            setMessages([...messages, inputValue]);
            setInputValue("");
        }
    };
    return (
        <div style={{ display: 'flex', gap: '32px', flexDirection: 'column', padding: '16px' }}>
            <div style={{ display: 'flex', gap: '12px', alignItems: 'flex-start', alignSelf: 'stretch' }}>
                <div style={{ display: 'flex', alignItems: 'flex-start', gap: '12px', alignSelf: 'stretch', width: '60%', flexDirection: 'column' }}>
                    <div style={{ width: '100%', overflow: 'hidden' }}>
                        <img src={`./images/video-fifa.png`} alt="fifa" style={{ borderRadius: '16px', objectFit: 'cover', objectPosition: 'center', width: '100%', height: '100%' }} />
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                        <div>
                            <p style={{ fontSize: '24px', color: 'rgba(255, 255, 255, 0.50)' }}>FIFA 2023</p>
                            <p style={{ fontSize: '12px', color: 'var(--gray-300, #D0D5DD)' }}>David Beckham</p>
                        </div>
                        <BadgeGroup />
                    </div>
                    <p style={{ fontSize: '18px', color: 'rgba(255, 255, 255, 0.50)' }}>Pick the player you believe in and bet your $CASH. Witness the Win: Watch the match and cheer for your champion. Claim Your Rewards: Winners enjoy their well-deserved payout!</p>
                </div>
                <div style={{ backgroundColor: 'rgba(255, 255, 255, 0.10)', borderRadius: '24px', height: '600px', display: 'flex', width: '40%', padding: '12px', justifyContent: 'space-between', flexDirection: 'column' }}>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                        <div style={{ borderRadius: '99px', width: '12px', height: '12px', backgroundColor: '#FF4949' }}>.</div>
                        <p style={{ color: 'white', fontSize: '24px' }}>Live Chat</p>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '16px', alignItems: 'flex-start', alignSelf: 'stretch', height: '80%', marginTop: '0px' }}>
                        {messages.map((message, index) => (
                            <div style={{ display: "flex", gap: '12px' }}>
                                <img src="./images/Avatar.png" alt="avatar" width="42px" height="42px" style={{ objectFit: 'cover', justifyContent: 'center' }} />
                                <div style={{ display: 'flex', flexDirection: 'column', gap: '3px' }}>
                                    <p style={{ fontSize: '12px', color: 'rgba(255, 255, 255, 0.50)' }}>David Beckham</p>
                                    <p style={{ fontSize: '16px', color: 'rgba(255, 255, 255, 0.80)' }} key={index}>{message}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '8px', alignSelf: 'stretch' }}>
                        <Input
                            backgroundColor={"rgba(255, 255, 255, 0.15)"}
                            color={"white"}
                            value={inputValue}
                            onChange={handleInputChange}
                            placeholder="Write Message..."
                            borderRadius={'12px'}
                            padding={'12px 24px'}
                            height={'56px'}
                        />
                        <IconButton width={'56px'} color={'white'} height={'56px'} backgroundColor={"var(--purple-500, #7A5AF8)"} icon={<FiSend />} onClick={handleSendMessage} />
                    </div>
                </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
                <p style={{ fontFamily: 'Monopol', fontSize: '24px', color: 'var(--base-white, #FFF)' }}>Place your bet</p>
                <div style={{ display: 'flex', gap: '16px' }}>
                    <div style={{ backgroundColor: 'rgba(255, 255, 255, 0.10)', borderRadius: '24px', display: 'flex', width: '60%', padding: '24px 36px', alignSelf: 'stretch', alignItems: 'center', justifyContent: 'space-between' }}>
                        <div style={{ display: "flex", flexDirection: 'column', alignItems: 'center' }}>
                            <img src="./images/playerA.png" alt="avatar" width={'105px'} height={'80px'} />
                            <p style={{ color: 'white', fontSize: '24px' }}>Team A</p>
                            <Badge backgroundColor={'rgba(0, 0, 0, 0.30)'} color={'white'} borderRadius={'4px'} padding={'4px 12px'} width={'54px'} height={'28px'}>3.9x</Badge>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <p style={{ fontSize: '24px', color: 'white' }}>Game Score</p>
                            <p style={{ color: 'var(--indigo-400, #8098F9)', fontSize: '64px' }}>0 : 0 </p>
                        </div>
                        <div style={{ display: "flex", flexDirection: 'column', alignItems: 'center' }}>
                            <img src="./images/playerB.png" alt="avatar" width={'105px'} height={'80px'} />
                            <p style={{ color: 'white', fontSize: '24px' }}>Team A</p>
                            <Badge backgroundColor={'rgba(0, 0, 0, 0.30)'} color={'white'} borderRadius={'4px'} padding={'4px 12px'} width={'54px'} height={'28px'}>3.9x</Badge>
                        </div>
                    </div>
                    <div style={{ backgroundColor: 'rgba(255, 255, 255, 0.10)', borderRadius: '24px', gap: '16px', display: 'flex', width: '40%', flexDirection: 'column', padding: '16px' }}>
                        <div style={{ display: 'flex', gap: '8px' }}>
                            <div style={{ display: 'flex', flexDirection: 'column', width: '70%', gap: '6px' }}>
                                <p style={{ fontFamily: 'Monopol', fontSize: '14px', color: 'rgba(255, 255, 255, 0.50)' }}>Bet amount</p>
                                <div style={{ display: 'flex' }}>
                                    <Input padding={'10px 0px 10px 14px'} color={'white'} placeholder="1000.00" />
                                    <Menu>
                                        <MenuButton as={Button} padding={'10px 14px'} backgroundColor={'rgba(255, 255, 255, 0.10)'} color={'white'} rightIcon={<FiArrowDown />}>
                                            USDT
                                        </MenuButton>
                                        <MenuList>
                                            <MenuItem>USDT</MenuItem>
                                            <MenuItem>CAD</MenuItem>
                                            <MenuItem>HKD</MenuItem>
                                        </MenuList>
                                    </Menu>
                                </div>
                            </div>
                            <Input color={'white'} placeholder="3.56" width={'30%'} alignSelf={'end'} />
                        </div>
                        <div style={{ display: 'flex', gap: '8px', flexDirection: 'column' }}>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <p style={{ color: 'white', fontSize: '18px' }}>Possible win: </p>
                                <p style={{ color: 'white', fontSize: '18px' }}>$3560 USDT</p>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <p style={{ color: 'white', fontSize: '18px' }}>Possible win: </p>
                                <Switch id="possible" />
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <p style={{ color: 'white', fontSize: '18px' }}>Overall coefficient: </p>
                                <p style={{ color: 'white', fontSize: '18px' }}>3.56x</p>
                            </div>
                        </div>
                        <Button background={'linear-gradient(45deg, #FF6C6C 0%, #DD7BFF 100%)'} _hover={{ background: '#DD7BFF' }} color={'white'} padding={'10px 18px'} borderRadius={'8px'} boxShadow={'0px 1px 2px 0px rgba(16, 24, 40, 0.05)'}>
                            Place Bet
                        </Button>
                    </div>
                </div>
            </div>
            <div>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                        <div style={{ borderRadius: '99px', width: '8px', height: '8px', backgroundColor: '#FF4949' }}>.</div>
                        <p style={{ color: 'white', fontSize: '16px' }}>LIVE MATCHES</p>
                    </div>
                    <div style={{ display: 'flex' }}>
                        <IconButton backgroundColor={'black'} color={'white'} icon={<FiArrowLeft />} />
                        <IconButton backgroundColor={'black'} color={'white'} icon={<FiArrowRight />} />
                    </div>
                </div>
                <div style={{ display: 'flex', gap: '16px', justifyContent: 'space-between' }}>
                    <LiveMatchComponent />
                    <LiveMatchComponent />
                    <LiveMatchComponent />
                    <LiveMatchComponent />
                </div>
            </div>
        </div>
    )
}

export default LiveBetting;