import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import LogoSection from "../../components/Logo";
import { IconButton, Badge, Button, Divider } from "@chakra-ui/react";
import { FiArrowLeft, FiArrowRight, FiEye, FiGlobe, FiClock } from 'react-icons/fi';
import { Gamepad } from '@styled-icons/fa-solid/Gamepad';

const LargeLogo = () => {
    return (
        <div style={{ position: "relative", width: `772.5px`, height: `450px` }}>
            <img src="./images/logomark.png"
                style={{
                    position: "absolute",
                    paddingRight: '374px',
                    maxHeight: '450px',
                    objectFit: "cover",
                }}
                alt="logomark" />
            <img
                src="./images/logotype.png"
                style={{
                    position: "absolute",
                    padding: '125px 0px 117px 87px',
                    maxWidth: `772.5px`,
                    objectFit: "cover",
                }}
                alt="logotype" />
        </div>
    )
}

const FirstSection = styled.div`
    background-image: ${`url('./images/background.gif')`};
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
`;

const HeaderCom = styled.div`
    display: flex;
    padding: 24px 80px;
    justify-content: space-between;
    align-items: center;
`
const MenuSection = styled.div`
    display: flex;
    padding: 12px 32px;
    justify-content: space-between;
    align-items: center;
    gap: 32px
`
const Textstyle = styled.p`
    color: var(--gray-50, #F9FAFB);
    font-family: Aeonik;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 150% */
`

const ContentSection = styled.div`
    display: flex;
    padding: 0px 112px;
    flex-direction: column;
    align-items: center;
    gap: 24px;
`
const SecondSection = styled.div`
    display: flex;
    padding: 96px 150px;
    flex-direction: column;
    align-items: center;
    gap: 32px;
    align-self: stretch;
`
const ThirdSection = styled.div`
    display: flex;
    padding: 96px 150px;
    align-items: center;
    gap: 64px;
    align-self: stretch;
`
const ForthSection = styled.div`
    display: flex;
    padding: 0px 150px 96px 150px;
    flex-direction: column;
    align-items: center;
    gap: 64px;
    align-self: stretch;
`
const FifthSection = styled.div`
    display: flex;
    padding: 24px 150px;
    gap: 64px;
`
const LastSection = styled.div`
    display: flex;
    padding: 48px 100px;
    gap: 24px;
    flex-direction: column;
    align-self: stretch;
    align-items: center;
`


const ImageComponent = ({ imgName }) => {
    return (
        <div style={{ width: '100%',overflow: 'hidden' }}>
            <img src={`./images/${imgName}.png`} alt="fifa" style={{ borderRadius: '16px', objectFit: 'cover', objectPosition: 'center', width: '100%', height: '100%' }} />
        </div>
    )
}
const BadgeGroup = () => {
    return (
        <div style={{ display: 'flex', gap: '10px', bottom: '0', alignItems: 'end' }}>
            <Badge backgroundColor={'#004EEB'} borderRadius={'16px'} padding={'4px 8px'} alignItems={'center'} gap={'4px'} height={'25px'} display={'flex'}>
                <div style={{ display: 'flex', color: 'white' }}><FiEye size={'16px'} /> 15K</div>
            </Badge>
            <Badge backgroundColor={'#6927DA'} borderRadius={'16px'} padding={'4px 8px'} alignItems={'center'} gap={'4px'} height={'25px'} display={'flex'}>
                <div style={{ display: 'flex', color: 'white' }}><FiGlobe size={'16px'} /> English</div>
            </Badge>
            <Badge backgroundColor={'#F79009'} borderRadius={'16px'} padding={'4px 8px'} alignItems={'center'} gap={'4px'} height={'25px'} display={'flex'}>
                <div style={{ display: 'flex', color: 'white' }}><FiClock size={'16px'} /> 15min ago</div>
            </Badge>
        </div>
    )
}
const LiveMatchComponent = () => {
    return (
        <div>
            <ImageComponent imgName={'video-fortnite'} />
            <div style={{ display: 'flex', gap: '10px', marginTop: '24px' }}>
                <img src="./images/Avatar.png" alt="avatar" style={{ objectFit: 'cover', justifyContent: 'center', borderRadius: '50%', width: '64px', height: '64px' }} />
                <div>
                    <p style={{ color: 'white', fontSize: '18px' }}>FIFA 2023</p>
                    <p style={{ color: '#D0D5DD', fontSize: '14px' }}>David Beckham</p>
                    <BadgeGroup />
                </div>
            </div>
        </div>
    )
}

const PastMatchComponent = () => {
    return (
        <div>
            <ImageComponent imgName={'video-fortnite'} />
            <div style={{ display: 'flex', gap: '10px', marginTop: '24px' }}>
                <img src="./images/Avatar.png" alt="avatar" style={{ objectFit: 'cover', justifyContent: 'center', borderRadius: '50%', width: '40px', height: '40px' }} />
                <div>
                    <p style={{ color: 'white', fontSize: '18px' }}>FIFA 2023</p>
                    <p style={{ color: '#D0D5DD', fontSize: '14px' }}>David Beckham</p>
                </div>
            </div>
        </div>
    )
}
function Homepage() {
    const navigate = useNavigate();
    const login=()=>{
        console.log('login')
        navigate('/live')
    }
    return (
        <div style={{ backgroundColor: 'black' }}>
            <FirstSection>
                <HeaderCom>
                    <LogoSection logowidth={103} logoheight={60} />
                    <MenuSection>
                        <button onClick={()=>navigate('/live')}><Textstyle>Home</Textstyle></button>
                        <button onClick={()=>navigate('/live')}><Textstyle>Live Betting</Textstyle></button>
                        <div style={{ display: 'flex', gap: '12px' }}>
                            <button onClick={()=>navigate('/tournament')}><Textstyle>Tournament</Textstyle></button>
                            <Badge borderRadius={'16px'} padding={'2px 8px'} background={'rgba(255, 98, 0, 0.30)'}>Hot 🔥</Badge>
                        </div>
                    </MenuSection>
                    <Button onClick={()=>login()} background={'linear-gradient(45deg, #FF6C6C 0%, #DD7BFF 100%)'} _hover={{ background: '#DD7BFF' }} color={'white'} padding={'10px 18px'} borderRadius={'8px'} boxShadow={'0px 1px 2px 0px rgba(16, 24, 40, 0.05)'}>
                        Connect Wallet
                    </Button>
                </HeaderCom>
                <ContentSection>
                    <LargeLogo />
                    <p style={{ 'color': 'white', 'fontSize': '32px' }}>Where Classic Gaming Meets Modern Rewards!</p>
                </ContentSection>
            </FirstSection>
            <SecondSection>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                    <div style={{ display: 'flex', gap: '10px' }}>
                        <p style={{ fontFamily: 'Monopol', fontSize: '48px', color: 'var(--base-white, #FFF)' }} >UPCOMING TOURNAMENT ALERT!</p>
                        <Badge borderRadius={'16px'} alignSelf={'center'} display={'flex'} alignItems={'center'} padding={'4px 12px'} color={'var(--orange-500, #EF6820)'} background={'rgba(255, 98, 0, 0.30)'} fontSize={'14px'}>
                            Hot 🔥
                        </Badge>
                    </div>
                    <p style={{ fontSize: '18px', color: 'var(--gray-300, #D0D5DD)' }}>Get ready to unleash your skills in our next grand event. With significant prizes and unlimited excitement, every game is a chance to prove your mettle. Stay tuned for details and be part of the next gaming revolution!"</p>
                </div>
                <img src="./images/fifa.png" alt="fifa" style={{ borderRadius: '10px', width: '100%' }} />
            </SecondSection>
            <ThirdSection>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '40px', flex: '1 0 0' }}>
                    <p style={{ color: 'var(--base-white, #FFF)', fontSize: '96px' }}> Choose Your Champion - Place Your Bet!</p>
                    <p style={{ alignSelf: 'stretch', color: '#D0D5DD', fontSize: '18px' }}>Welcome to the betting arena of Arcade CA$HINO. Here, you decide the winners, place your bets on your favorite players, and revel in the triumph of victory. Your game, your rules</p>
                </div>
                <img src="./images/video-mega.png" alt="logomark" style={{ borderRadius: '10px', width: '50%' }} />
            </ThirdSection>
            <ForthSection>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '20px', width: '60%', alignSelf: 'flex-start' }}>
                    <p style={{ color: 'white', fontSize: '48px' }}>How It Works</p>
                    <p style={{ color: 'var(--gray-300, #D0D5DD)', fontSize: '18px' }}>Pick the player you believe in and bet your $CASH. Witness the Win: Watch the match and cheer for your champion. Claim Your Rewards: Winners enjoy their well-deserved payout!</p>
                </div>
                <div style={{display:'flex'}}>
                <img src="./images/how1.svg" alt="logomark" style={{ borderRadius: '10px', width: '50%' }} />
                <img src="./images/how2.svg" alt="logomark" style={{ borderRadius: '10px', width: '50%' }} />
                <img src="./images/how2.svg" alt="logomark" style={{ borderRadius: '10px', width: '50%' }} />
                </div>
                <div>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                            <div style={{ borderRadius: '99px', width: '8px', height: '8px', backgroundColor: '#FF4949' }}>.</div>
                            <p style={{ color: 'white', fontSize: '16px' }}>LIVE MATCHES</p>
                        </div>
                        <div style={{ display: 'flex' }}>
                            <IconButton backgroundColor={'black'} color={'white'} icon={<FiArrowLeft />} />
                            <IconButton backgroundColor={'black'} color={'white'} icon={<FiArrowRight />} />
                        </div>
                    </div>
                    <div style={{ display: 'flex', gap: '16px', justifyContent: 'space-between' }}>
                        <LiveMatchComponent />
                        <LiveMatchComponent />
                        <LiveMatchComponent />
                        <LiveMatchComponent />
                    </div>
                </div>
                <div>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
                            <Gamepad color="white" size={24} />
                            <p style={{color:'white', fontSize:'16px'}}>PAST MATCHES</p>
                            <button><p style={{fontSize:'14px', color:'#DD7BFF'}}>View All</p></button>
                        </div>
                        <div style={{ display: 'flex' }}>
                            <IconButton backgroundColor={'black'} color={'white'} icon={<FiArrowLeft />} />
                            <IconButton backgroundColor={'black'} color={'white'} icon={<FiArrowRight />} />
                        </div>
                    </div>
                    <div style={{ display: 'flex', gap: '16px', justifyContent: 'space-between' }}>
                        <PastMatchComponent />
                        <PastMatchComponent />
                        <PastMatchComponent />
                        <PastMatchComponent />
                        <PastMatchComponent />
                        <PastMatchComponent />
                    </div>
                </div>
            </ForthSection>
            <FifthSection>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '40px', justifyContent: 'center' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
                        <p style={{ color: '#DD7BFF', fontSize: '16px' }}>About us</p>
                        <p style={{ color: 'white', fontSize: '48px' }}>CLASSIC GAMING MEETS MODERN REWARDS</p>
                    </div>
                    <p style={{ color: '#D0D5DD', fontSize: '18px' }}>Welcome to CA$HINO, a universe where gaming transcends boundaries. We're combining the nostalgic euphoria of arcade games with contemporary esports and integrating it all with a cutting-edge web3 betting platform. Participants can dive into various tournaments, face off in Player vs Player battles, bet on their favorite competitors, or strive for high scores in arcade classics and modern hits alike."</p>
                </div>
                <img
                    src="./images/about1.png"
                    alt="about"
                    style={{
                        borderRadius: '10px',
                        width: '50%'
                    }}
                />
            </FifthSection>
            <LastSection>
                <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                    <LogoSection logowidth={103} logoheight={60} />
                    <MenuSection>
                        <button onClick={()=>navigate('/live')}><Textstyle>Home</Textstyle></button>
                        <button onClick={()=>navigate('/live')}><Textstyle>Live Betting</Textstyle></button>
                        <div style={{ display: 'flex', gap: '12px' }}>
                            <button onClick={()=>navigate('/tournament')}><Textstyle>Tournament</Textstyle></button>
                            <Badge borderRadius={'16px'} padding={'2px 8px'} background={'rgba(255, 98, 0, 0.30)'}>Hot 🔥</Badge>
                        </div>
                    </MenuSection>
                </div>
                <Divider />
                <div style={{ display: 'flex', paddingTop: '32px', justifyContent: 'space-between', width: '100%' }}>
                    <p style={{ color: 'var(--gray-300, #D0D5DD)' }}>© 2077 Arcade CA$HINO. All rights reserved.</p>
                    <MenuSection>
                        <p style={{ color: 'var(--gray-300, #D0D5DD)', fontSize: '16px' }}>Terms</p>
                        <p style={{ color: 'var(--gray-300, #D0D5DD)', fontSize: '16px' }}>Privacy</p>
                        <p style={{ color: 'var(--gray-300, #D0D5DD)', fontSize: '16px' }}>Cookies</p>
                    </MenuSection>
                </div>
            </LastSection>
        </div>
    )
}

export default Homepage;