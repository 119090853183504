import { Container, Table, TableContainer, TableCaption, Tr, Th, Td, Tbody, Tfoot, Thead } from "@chakra-ui/react";

const xxx = () => {
    return (
        <Td gap="8px" display={'flex'} justifyContent={'center'} alignItems={'center'} alignSelf={'stretch'} borderTopLeftRadius={'10px'} borderBottomLeftRadius={'10px'}>
            <img src="./images/fortnite.png" alt="fortnite" width="24px" height="24px" style={{ objectFit: 'cover' }} />
            <p style={{ color: 'white', fontSize: '13px' }}>Fortnite</p>
        </Td>
    )
}

function Bet() {
    return (
        <div style={{ display: 'flex', padding: '44px 16px 16px 18px', flexDirection: 'column', alignItems: 'flex-start', gap: '32px' }}>
            <p style={{ color: 'white', fontSize: '24px' }}>Current Bets</p>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '12px', justifyContent: 'center', width: '100%' }}>
                <div style={{ display: 'flex', padding: '8px', backgroundColor: 'rgba(255, 255, 255, 0.15)', width: '100%', borderRadius: '8px' }}>
                    <div style={{ padding: '8px 12px', width: '16.6%', color: 'white', textAlign: 'center' }}>GAME</div>
                    <div style={{ padding: '8px 12px', width: '16.6%', color: 'white', textAlign: 'center' }}>PLAYER</div>
                    <div style={{ padding: '8px 12px', width: '16.6%', color: 'white', textAlign: 'center' }}>TIME</div>
                    <div style={{ padding: '8px 12px', width: '16.6%', color: 'white', textAlign: 'center' }}>WAGER</div>
                    <div style={{ padding: '8px 12px', width: '16.6%', color: 'white', textAlign: 'center' }}>MULTIPLAYER</div>
                    <div style={{ padding: '8px 12px', width: '16.6%', color: 'white', textAlign: 'center' }}>PAYOUT</div>
                </div>
                <div style={{ display: 'flex',width: '100%', flexDirection:'column', gap:'4px' }}>
                    <div style={{ display: 'flex', backgroundColor: 'rgba(255, 255, 255, 0.05)', width: '100%', borderRadius:'4px' }}>
                        <div style={{ padding: '8px 12px', color: 'white', gap: '8px', display: 'flex', alignItems: 'center', width: '16.6%', justifyContent: 'center'}}>
                            <img src="./images/fortnite.png" alt="fortnite" width="24px" height="24px" style={{ objectFit: 'cover' }} />
                            <p style={{ color: 'white', fontSize: '13px' }}>Fortnite</p>
                        </div>
                        <div style={{ padding: '8px 12px', color: 'white', gap: '8px', display: 'flex', width: '16.6%', alignItems: 'center', justifyContent: 'center'}}>
                            <img src="./images/playerA.png" alt="fortnite" width="24px" height="24px" style={{ objectFit: 'cover' }} />
                            <p style={{ color: 'white', fontSize: '13px' }}>wooka</p>
                        </div>
                        <p style={{ padding: '8px 12px', color: 'white', width: '16.6%', textAlign: 'center', alignSelf:'center' }}>3S</p>
                        <p style={{ padding: '8px 12px', color: 'white', width: '16.6%', textAlign: 'center', alignSelf:'center'  }}>$800.00</p>
                        <p style={{ padding: '8px 12px', color: 'white', width: '16.6%', textAlign: 'center', alignSelf:'center'  }}>0.32x</p>
                        <p style={{ padding: '8px 12px', color: '#72F238', width: '16.6%', textAlign: 'center', alignSelf:'center' }}>$256.00</p>
                    </div>
                    <div style={{ display: 'flex',backgroundColor: 'rgba(255, 255, 255, 0.05)', width: '100%', borderRadius:'4px' }}>
                        <div style={{ padding: '8px 12px', color: 'white', gap: '8px', display: 'flex', alignItems: 'center', width: '16.6%', justifyContent: 'center'}}>
                            <img src="./images/fortnite.png" alt="fortnite" width="24px" height="24px" style={{ objectFit: 'cover' }} />
                            <p style={{ color: 'white', fontSize: '13px' }}>Fortnite</p>
                        </div>
                        <div style={{ padding: '8px 12px', color: 'white', gap: '8px', display: 'flex', width: '16.6%', alignItems: 'center', justifyContent: 'center'}}>
                            <img src="./images/playerA.png" alt="fortnite" width="24px" height="24px" style={{ objectFit: 'cover' }} />
                            <p style={{ color: 'white', fontSize: '13px' }}>wooka</p>
                        </div>
                        <p style={{ padding: '8px 12px', color: 'white', width: '16.6%', textAlign: 'center', alignSelf:'center' }}>3S</p>
                        <p style={{ padding: '8px 12px', color: 'white', width: '16.6%', textAlign: 'center', alignSelf:'center'  }}>$800.00</p>
                        <p style={{ padding: '8px 12px', color: 'white', width: '16.6%', textAlign: 'center', alignSelf:'center'  }}>0.32x</p>
                        <p style={{ padding: '8px 12px', color: '#72F238', width: '16.6%', textAlign: 'center', alignSelf:'center' }}>$256.00</p>
                    </div>
                    <div style={{ display: 'flex', backgroundColor: 'rgba(255, 255, 255, 0.05)', width: '100%', borderRadius:'4px' }}>
                        <div style={{ padding: '8px 12px', color: 'white', gap: '8px', display: 'flex', alignItems: 'center', width: '16.6%', justifyContent: 'center'}}>
                            <img src="./images/fortnite.png" alt="fortnite" width="24px" height="24px" style={{ objectFit: 'cover' }} />
                            <p style={{ color: 'white', fontSize: '13px' }}>Fortnite</p>
                        </div>
                        <div style={{ padding: '8px 12px', color: 'white', gap: '8px', display: 'flex', width: '16.6%', alignItems: 'center', justifyContent: 'center'}}>
                            <img src="./images/playerA.png" alt="fortnite" width="24px" height="24px" style={{ objectFit: 'cover' }} />
                            <p style={{ color: 'white', fontSize: '13px' }}>wooka</p>
                        </div>
                        <p style={{ padding: '8px 12px', color: 'white', width: '16.6%', textAlign: 'center', alignSelf:'center' }}>3S</p>
                        <p style={{ padding: '8px 12px', color: 'white', width: '16.6%', textAlign: 'center', alignSelf:'center'  }}>$800.00</p>
                        <p style={{ padding: '8px 12px', color: 'white', width: '16.6%', textAlign: 'center', alignSelf:'center'  }}>0.32x</p>
                        <p style={{ padding: '8px 12px', color: '#72F238', width: '16.6%', textAlign: 'center', alignSelf:'center' }}>$256.00</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Bet;